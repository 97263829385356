<template>
  <!-- //解决方案 -->
  <div class=''>
    <!-- 轮播图 -->
    <div class="relative">
      <img :src="bannerImg" class="w-max" style="object-fit: cover;">

      <!-- tab栏 -->
      <tabList :tabList='tabList' @changeTab='changeTab' />
      <!-- //tab栏 -->
    </div>
    <!-- //轮播图 -->

    <router-view></router-view>
    <!-- 		<div class="container pt120" >
			<div class="goods_box fs18 color9D">
				<div class="goods_item relative h744 mb160" v-for="i in 3" :key="i" @click="gotoD">
					<div :class="[i%2==0?'goods_img_even':'goods_img']">
						<img src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1113%2F052420110515%2F200524110515-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1664682171&t=f0889de449e05705b7438017561dca89"
							class="w744 h744"  />
					</div>
					<div :class="['w720 ',i%2==0?'goods_content_even':'goods_content']">
						<div class="fs46 fw-600 color3">标题：内容内容</div>
						<div class="mt42">
							文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案
						</div>
						<div class="flex row-center">
							<div class="seeD mt42 text-center">查看详情</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
  </div>
</template>

<script>
import tabList from '@/components/tabList.vue'
import goodsList from '@/components/goodsList'
import { getSolutionCategory, getCoopCase, getSolutions } from '@/api'
import bannerImgJs from '@/mixin/bannerImg'
export default {
  data () {
    return {
      bannerImg: '',
      tabList: [],
      solutionList: [],
      bannerType: 3
    };
  },
  mixins: [bannerImgJs],
  components: { tabList, goodsList },

  created () {
    this.$store.commit('editRouterName', this.$route.name)
    //获取解决方案类目
    this.getSolutionCategory()
  },

  mounted () { },

  methods: {
    async getSolutionCategory () {
      const res = await getSolutionCategory()
      this.tabList = res.data
      this.getSolutions(res.data[0].id)
    },
    //获取方案列表
    async getSolutions (categoryId) {
      const res = await getSolutions({ categoryId })
      this.solutionList = res.data
      this.$store.commit('solution/editList', res.data)
    },
    // 子传父
    changeTab (id) {
      this.getSolutions(id)
    }
  }
}
</script>
<style lang='scss' scoped>
/* .img_box {
  height: 1024px;
} */
</style>
