<template>
  <div class="content pl481 pt120 pr100 pb160 ">
    <div class="goods_box fs18 color9D">
      <div class="goods_item relative h560 mb160 pointer " v-for="(item,i) in coreList" :key="i"
        @click="$router.push(`/coreJiShuD/${item.id}`)">
        <div :class="[i%2==0?'goods_img':'goods_img_even']"> 
          <img :src="item.pcPic" class="w560 h560 mr60 img" style="object-fit: cover;"/>
        </div>
        <div :class="['w720 flex1',i%2==0?'goods_content':'goods_content_even']"> 
          <div class="fs36 fw-600 color3 goodsname">{{item.skillName}}</div>
          <div class="mt42 pointer skillCont_box lh45 fw-600" style="text-indent: 2em;" >
            {{item.skillIntro}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'coreListCom',
  props: {
    coreList: {}
  },
  data () {
    return {
    };
  },

  components: {},

  created () { },

  mounted () { },

  methods: {
  }
}

</script>
<style lang='scss' scoped>
.content {
  background: #ffffff;
  img {
	    transition: all .3s;
  }
  .goods_item:hover {
	  .goodsname {
		  color: #F7B500;
	  }
	  .skillCont_box {
		    color: #F7B500;
	  }
	  .img {
		
		  transform: scale(1.1);
	  }
  }

  .goods_box {
	  z-index: 999;
    /* width: 1340px; */
    height: 100%;
    .goods_img {
      position: absolute;
      left: 0;
      top: 0;
    }
    .goods_img_even {
      position: absolute;
      right: 0;
      top: 0;
    }
    .goods_content {
      position: absolute;
      right: 0;
      top: 0;
    }
    .goods_content_even {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
.skillCont_box {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 15;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 18px
}

</style>